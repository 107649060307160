export const getIleggelserVenterTitle = (reportData) => {
  return reportData &&
    (reportData.a_IleggelseInTimeQuarantineCount ||
      reportData.a_IleggelseInTimeQuarantineCount === 0)
    ? `Ileggelser som venter på å bli sendt (${reportData.a_IleggelseInTimeQuarantineCount})`
    : "Ileggelser som venter på å bli sendt";
};

export const getIleggelserStoppetTitle = (reportData) => {
  return reportData &&
    (reportData.b_IleggelseStoppetAvHgkSystemetCount ||
      reportData.b_IleggelseStoppetAvHgkSystemetCount === 0)
    ? `Ileggelser stoppet av fagsystemet (${reportData.b_IleggelseStoppetAvHgkSystemetCount})`
    : "Ileggelser stoppet av fagsystemet";
};

export const getIleggelserKlarTitle = (reportData) => {
  return reportData &&
    (reportData.c_IleggelseWillBeSentCount ||
      reportData.c_IleggelseWillBeSentCount === 0)
    ? `Ileggelser klare til å sendes (${reportData.c_IleggelseWillBeSentCount})`
    : "Ileggelser klare til å sendes";
};

export const getIleggelserBehandlesInnkrevingTitle = (reportData) => {
  return reportData &&
    (reportData.d_IleggelseInIneQueueCount ||
      reportData.d_IleggelseInIneQueueCount === 0)
    ? `Ileggelser under behandling hos Innkreving (${reportData.d_IleggelseInIneQueueCount})`
    : "Ileggelser under behandling hos Innkreving";
};

export const getIleggelserFeiletTitle = (reportData) => {
  return reportData &&
    (reportData.f_IleggelseHasBeenRejectedCount ||
      reportData.f_IleggelseHasBeenRejectedCount === 0)
    ? `Ileggelser feilet hos Innkreving (${reportData.f_IleggelseHasBeenRejectedCount})`
    : "Ileggelser feilet hos Innkreving";
};

export const getIleggelserVellykketTitle = (reportData) => {
  return reportData &&
    (reportData.e_IleggelseHasBeenAcceptedCount ||
      reportData.e_IleggelseHasBeenAcceptedCount === 0)
    ? `Ileggelser som er behandlet (${reportData.e_IleggelseHasBeenAcceptedCount})`
    : "Ileggelser som er behandlet";
};

export const getIleggelserVenterHeaders = {
  "Ilegg.nr.": { cellKey: "ileggelsesnummer" },
  Dato: { cellKey: "dato" },
  Ileggelsestype: { cellKey: "ileggelse_type" },
  Kjennemerke: { cellKey: "kjennemerke" },
  Nasjon: { cellKey: "nasjon" },
  "Overt.kode": {},
};

export const getIleggelserStoppetHeaders = {
  "Ilegg.nr.": { cellKey: "ileggelsesnummer" },
  Dato: { cellKey: "dato" },
  Ileggelsestype: { cellKey: "ileggelse_type" },
  Kjennemerke: { cellKey: "kjennemerke" },
  Nasjon: { cellKey: "nasjon" },
  "Overt.kode": {},
  Feil: {},
};

export const getIleggelserKlarHeaders = {
  "Ilegg.nr.": { cellKey: "ileggelsesnummer" },
  Dato: { cellKey: "dato" },
  Ileggelsestype: { cellKey: "ileggelse_type" },
  Kjennemerke: { cellKey: "kjennemerke" },
  Nasjon: { cellKey: "nasjon" },
  "Overt.kode": {},
};

export const getIleggelserBehandlesInnkrevingHeaders = {
  "Ilegg.nr.": { cellKey: "ileggelsesnummer" },
  Dato: { cellKey: "dato" },
  Ileggelsestype: { cellKey: "ileggelse_type" },
  Kjennemerke: { cellKey: "kjennemerke" },
  Nasjon: { cellKey: "nasjon" },
  "Overt.kode": {},
  "Sent INE dato": { cellKey: "sent_to_ine" },
};

export const getIleggelserFeiletHeaders = {
  "Ilegg.nr.": { cellKey: "ileggelsesnummer" },
  Dato: { cellKey: "dato" },
  Ileggelsestype: { cellKey: "ileggelse_type" },
  Kjennemerke: { cellKey: "kjennemerke" },
  Nasjon: { cellKey: "nasjon" },
  "Overt.kode": {},
  "Behandlet INE dato": { cellKey: "received_from_ine" },
  Feil: {},
};

export const getIleggelserVellykketHeaders = {
  "Ilegg.nr.": { cellKey: "ileggelsesnummer" },
  Dato: { cellKey: "dato" },
  Ileggelsestype: { cellKey: "ileggelse_type" },
  Kjennemerke: { cellKey: "kjennemerke" },
  Nasjon: { cellKey: "nasjon" },
  "Overt.kode": {},
  "Behandlet INE dato": { cellKey: "received_from_ine" },
};
