import React from "react";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";
import { withRouter } from "react-router-dom";

import { ReactComponent as IconClose } from "../../images/icon-close.svg";

const styles = {
  closeIcon: { width: 14, height: 14, margin: 6 },
  closeButton: { display: "flex", alignItems: "center" },
};
function CloseButton({ text, customHandleClick }) {
  return (
    <Button
      type="button"
      className="btn-bym-standard"
      style={styles.closeButton}
      onClick={customHandleClick ? () => customHandleClick() : undefined}
    >
      <IconClose />
      <span>{text}</span>
    </Button>
  );
}

CloseButton.propTypes = {
  text: PropTypes.string,
  hasCloseIcon: PropTypes.bool,
};

export default withRouter(CloseButton);
