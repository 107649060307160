import React from "react";
import InnkrevingStatusNavbar from "./InnkrevingStatusNavbar";

import sitePaths from "../../constants/sitePaths";
import { Route, Switch } from "react-router";
import {
  getMiljogebyrVenterTitle,
  getMiljogebyrStoppetTitle,
  getMiljogebyrKlarTitle,
  getMiljogebyrBehandlesInnkrevingTitle,
  getMiljogebyrFeiletTitle,
  getMiljogebyrVellykketTitle,
  getMiljogebyrVenterHeaders,
  getMiljogebyrStoppetHeaders,
  getMiljogebyrKlarHeaders,
  getMiljogebyrBehandlesInnkrevingHeaders,
  getMiljogebyrFeiletHeaders,
  getMiljogebyrVellykketHeaders,
} from "./InnkrevingMiljogebyrDataHelpers";
import {
  getMiljogebyrVedtakVenterTitle,
  getMiljogebyrVedtakStoppetTitle,
  getMiljogebyrVedtakKlarTitle,
  getMiljogebyrVedtakBehandlesInnkrevingTitle,
  getMiljogebyrVedtakFeiletTitle,
  getMiljogebyrVedtakVellykketTitle,
  getMiljogebyrVedtakVenterHeaders,
  getMiljogebyrVedtakStoppetHeaders,
  getMiljogebyrVedtakKlarHeaders,
  getMiljogebyrVedtakBehandlesInnkrevingHeaders,
  getMiljogebyrVedtakFeiletHeaders,
  getMiljogebyrVedtakVellykketHeaders,
} from "./InnkrevingMiljogebyrVedtakDataHelpers";
import InnkrevingMiljogebyrData from "./InnkrevingMiljogebyrData";
import InnkrevingMiljogebyrVedtakData from "./InnkrevingMiljogebyrVedtakData";

const InnkrevingMiljogebyr = ({ reportData, isLoading }) => {
  const statusNavbarItems = [
    {
      name: "Venter i fagsystemet",
      path: sitePaths.innkreving.miljogebyr.venter.shortUrl,
    },
    {
      name: "Stoppet av fagsystemet",
      path: sitePaths.innkreving.miljogebyr.stoppet.shortUrl,
    },
    {
      name: "Klar til Innkreving",
      path: sitePaths.innkreving.miljogebyr.klar.shortUrl,
    },
    {
      name: "Behandles av Innkreving",
      path: sitePaths.innkreving.miljogebyr.behandlesInnkreving.shortUrl,
    },
    {
      name: "Feil fra Innkreving",
      path: sitePaths.innkreving.miljogebyr.feilet.shortUrl,
    },
    {
      name: "Vellykket hos Innkreving (siste 100)",
      path: sitePaths.innkreving.miljogebyr.vellykket.shortUrl,
    },
  ];

  return (
    <>
      <InnkrevingStatusNavbar items={statusNavbarItems} />
      <section style={{ paddingRight: 50, paddingLeft: 50 }}>
        <h1>Innkreving - Miljøgebyr</h1>
        <Switch>
          <Route exact path={sitePaths.innkreving.miljogebyr.venter.shortUrl}>
            <>
              <div
                style={{
                  marginTop: 50,
                  marginBottom: 50,
                }}
              >
                <InnkrevingMiljogebyrData
                  id="miljoGebyrInTimeQuarantineList"
                  title={getMiljogebyrVenterTitle(reportData)}
                  headers={getMiljogebyrVenterHeaders}
                  miljogebyr={
                    reportData && reportData.a_MiljoGebyrInTimeQuarantineList
                  }
                  isLoading={isLoading}
                />
              </div>
              <div>
                <InnkrevingMiljogebyrVedtakData
                  id="miljoGebyrVedtakInTimeQuarantineList"
                  title={getMiljogebyrVedtakVenterTitle(reportData)}
                  headers={getMiljogebyrVedtakVenterHeaders}
                  vedtak={
                    reportData &&
                    reportData.a_MiljoGebyrVedtakInTimeQuarantineList
                  }
                  isLoading={isLoading}
                />
              </div>
            </>
          </Route>
          <Route exact path={sitePaths.innkreving.miljogebyr.stoppet.shortUrl}>
            <>
              <div
                style={{
                  marginTop: 50,
                  marginBottom: 50,
                }}
              >
                <InnkrevingMiljogebyrData
                  id="miljoGebyrStoppetAvHgkSystemetList"
                  title={getMiljogebyrStoppetTitle(reportData)}
                  headers={getMiljogebyrStoppetHeaders}
                  miljogebyr={
                    reportData &&
                    reportData.b_MiljoGebyrStoppetAvHgkSystemetList
                  }
                  isLoading={isLoading}
                  inkludFail={true}
                />
              </div>
              <div>
                <InnkrevingMiljogebyrVedtakData
                  id="miljoGebyrVedtakStoppetAvHgkSystemetList"
                  title={getMiljogebyrVedtakStoppetTitle(reportData)}
                  headers={getMiljogebyrVedtakStoppetHeaders}
                  vedtak={
                    reportData &&
                    reportData.b_MiljoGebyrVedtakStoppetAvHgkSystemetList
                  }
                  isLoading={isLoading}
                  inkludFail={true}
                />
              </div>
            </>
          </Route>
          <Route exact path={sitePaths.innkreving.miljogebyr.klar.shortUrl}>
            <>
              <div
                style={{
                  marginTop: 50,
                  marginBottom: 50,
                }}
              >
                <InnkrevingMiljogebyrData
                  id="miljoGebyrWillBeSentList"
                  title={getMiljogebyrKlarTitle(reportData)}
                  headers={getMiljogebyrKlarHeaders}
                  miljogebyr={
                    reportData && reportData.c_MiljoGebyrWillBeSentList
                  }
                  isLoading={isLoading}
                />
              </div>
              <div>
                <InnkrevingMiljogebyrVedtakData
                  id="miljoGebyrVedtakWillBeSentList"
                  title={getMiljogebyrVedtakKlarTitle(reportData)}
                  headers={getMiljogebyrVedtakKlarHeaders}
                  vedtak={
                    reportData && reportData.c_MiljoGebyrVedtakWillBeSentList
                  }
                  isLoading={isLoading}
                />
              </div>
            </>
          </Route>
          <Route
            exact
            path={sitePaths.innkreving.miljogebyr.behandlesInnkreving.shortUrl}
          >
            <>
              <div
                style={{
                  marginTop: 50,
                  marginBottom: 50,
                }}
              >
                <InnkrevingMiljogebyrData
                  id="miljoGebyrInIneQueueList"
                  title={getMiljogebyrBehandlesInnkrevingTitle(reportData)}
                  headers={getMiljogebyrBehandlesInnkrevingHeaders}
                  miljogebyr={
                    reportData && reportData.d_MiljoGebyrInIneQueueList
                  }
                  isLoading={isLoading}
                  inkludSentIneDato={true}
                />
              </div>
              <div>
                <InnkrevingMiljogebyrVedtakData
                  id="miljoGebyrVedtakInIneQueueList"
                  title={getMiljogebyrVedtakBehandlesInnkrevingTitle(
                    reportData
                  )}
                  headers={getMiljogebyrVedtakBehandlesInnkrevingHeaders}
                  vedtak={
                    reportData && reportData.d_MiljoGebyrVedtakInIneQueueList
                  }
                  isLoading={isLoading}
                  inkludSentIneDato={true}
                />
              </div>
            </>
          </Route>
          <Route exact path={sitePaths.innkreving.miljogebyr.feilet.shortUrl}>
            <>
              <div
                style={{
                  marginTop: 50,
                  marginBottom: 50,
                }}
              >
                <InnkrevingMiljogebyrData
                  id="miljoGebyrHasBeenRejectedList"
                  title={getMiljogebyrFeiletTitle(reportData)}
                  headers={getMiljogebyrFeiletHeaders}
                  miljogebyr={
                    reportData && reportData.f_MiljoGebyrHasBeenRejectedList
                  }
                  isLoading={isLoading}
                  inkludFail={true}
                  inkludBehandletIneDato={true}
                />
              </div>
              <div>
                <InnkrevingMiljogebyrVedtakData
                  id="miljoGebyrVedtakHasBeenRejectedList"
                  title={getMiljogebyrVedtakFeiletTitle(reportData)}
                  headers={getMiljogebyrVedtakFeiletHeaders}
                  vedtak={
                    reportData &&
                    reportData.f_MiljoGebyrVedtakHasBeenRejectedList
                  }
                  isLoading={isLoading}
                  inkludFail={true}
                  inkludBehandletIneDato={true}
                />
              </div>
            </>
          </Route>
          <Route
            exact
            path={sitePaths.innkreving.miljogebyr.vellykket.shortUrl}
          >
            <>
              <div
                style={{
                  marginTop: 50,
                  marginBottom: 50,
                }}
              >
                <InnkrevingMiljogebyrData
                  id="miljoGebyrHasBeenAccepted_last_limit_100_List"
                  title={getMiljogebyrVellykketTitle(reportData)}
                  headers={getMiljogebyrVellykketHeaders}
                  miljogebyr={
                    reportData &&
                    reportData.e_MiljoGebyrHasBeenAccepted_last_limit_100_List
                  }
                  isLoading={isLoading}
                  inkludBehandletIneDato={true}
                />
              </div>
              <div>
                <InnkrevingMiljogebyrVedtakData
                  id="miljoGebyrVedtakHasBeenAccepted_last_limit_100_List"
                  title={getMiljogebyrVedtakVellykketTitle(reportData)}
                  headers={getMiljogebyrVedtakVellykketHeaders}
                  vedtak={
                    reportData &&
                    reportData.e_MiljoGebyrVedtakHasBeenAccepted_last_limit_100_List
                  }
                  isLoading={isLoading}
                  inkludBehandletIneDato={true}
                />
              </div>
            </>
          </Route>
        </Switch>
      </section>
    </>
  );
};

export default InnkrevingMiljogebyr;
