import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import isEmpty from "lodash/isEmpty";

import KlageSearchFormContainer from "./klageSearchFormContainer";
import KlageVedtakContainer from "./VedtakerContainer";
import KlageTitleAndBeslutningsLinker from "./KlageTitleAndBeslutningsLinker";
import { Container, Row, Col } from "react-bootstrap";
import KlageInfoContainer from "./KlageInfoContainer";
import IleggelseNumberTabsContainer from "./IleggelseNumberTabsContainer";
import * as title from "../../constants/titles";
import "./Klage.css";

export class KlagePage extends Component {
  componentDidMount() {
    document.title = title.klage;
  }
  ManglerInfo = () => {
    const { sak } = this.props;
    const klageInfo = sak && sak.kontakt;
    if (klageInfo === undefined) return false;
    return (
      isEmpty(klageInfo) ||
      isEmpty(klageInfo.postnummer) ||
      isEmpty(klageInfo.poststed)
    );
  };
  showContent = () => {
    const { klageId, KlageIleggelserFilterFormState } = this.props;
    return klageId !== "" && KlageIleggelserFilterFormState.saksnummer !== "";
  };

  render() {
    const { sak, klageId } = this.props;
    const manglerInfo = this.ManglerInfo();

    return (
      <section>
        <KlageSearchFormContainer />
        {klageId && (
          <Container fluid>
            <KlageTitleAndBeslutningsLinker
              sak={sak}
              klageId={klageId}
              manglerInfo={this.ManglerInfo()}
            />
            {this.showContent() && (
              <div className="klageContainer">
                <Container className="KlageOgIleggelseTabber" fluid>
                  <Row>
                    <Col xl={4}>
                      <KlageInfoContainer />
                    </Col>
                    <Col xl={8}>
                      <IleggelseNumberTabsContainer manglerInfo={manglerInfo} />
                    </Col>
                  </Row>
                </Container>
                <KlageVedtakContainer manglerInfo={manglerInfo} />
                <div style={{ hight: 300 }} />
              </div>
            )}
          </Container>
        )}
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  klageId:
    state.klageDetail && state.klageDetail.id ? state.klageDetail.id : "",
  sak: state.sak,
  KlageIleggelserFilterFormState: state.forms.KlageIleggelseFilterFormState,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(KlagePage);
