import React, { useEffect } from "react";

import NavigationBarContainer from "../NavigationBarContainer";
import MakuleringerFilterFormContainer from "./MakuleringerFilterFormContainer";
import MakuleringerExcel from "./MakuleringerExcel";
import MakuleringerList from "./MakuleringerList";

import createPaginationControl from "../../Common/createPaginationControl";

import { getMakuleringer as getRapportMakuleringerAction } from "../../../actions/rapporter/makuleringerActions";
import { rapporter } from "../../../constants/titles";

const PaginationControl = createPaginationControl(
  "rapporter.makuleringerRapport",
  {
    apiCallAction: getRapportMakuleringerAction,
    queriesForApiReducerName: "forms.makuleringerFilterForm",
  }
);

const Makuleringer = ({
  makuleringerRapport,
  makuleringerSubmittedForm,
  sort,
  onSort,
}) => {
  useEffect(() => {
    document.title = rapporter.makuleringer;
  }, []);

  return (
    <div>
      <NavigationBarContainer page="makuleringer" />
      <MakuleringerFilterFormContainer />
      <div className="rapport-buttons-container">
        <MakuleringerExcel
          makuleringerSubmittedForm={makuleringerSubmittedForm}
          makuleringerRapport={makuleringerRapport}
        />
      </div>
      <div className="rapport-table">
        <MakuleringerList
          makuleringerRapport={makuleringerRapport.items}
          sort={sort}
          onSort={onSort}
        />
        <PaginationControl storeKey="rapporter.makuleringerRapport" />
      </div>
    </div>
  );
};

export default Makuleringer;
