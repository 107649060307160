import { useEffect, useCallback } from "react";
import { useAuth } from "react-oidc-context";
import { useLocation } from "react-router-dom";
import * as title from "../../constants/titles";
import { Button } from "react-bootstrap";

const LoginPage = () => {
  const auth = useAuth();
  const location = useLocation();

  const locationState = location.state;

  useEffect(() => {
    window.title = title.loggInn;
  }, []);

  const handleLoggInn = useCallback(() => {
    return auth.signinRedirect({
      state: locationState,
    });
  }, [auth, locationState]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "80vh",
        maxWidth: 450,
        margin: "auto",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          fontSize: 36,
          lineHeight: 1.2,
          wordBreak: "break-word",
          color: "#2a2859",
          textAlign: "center",
          marginBottom: 20,
        }}
      >
        Velkommen til saksbehandlingsside for ileggelser i Oslo Kommune
      </div>
      <div>
        <Button data-testid="logg-inn-button" size="lg" onClick={handleLoggInn}>
          Logg inn
        </Button>
      </div>
    </div>
  );
};

export default LoginPage;
