import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import LoadButton from "../../LoadButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { getMiljogebyrKopi as getMiljogebyrKopiAction } from "../../../../actions/miljogebyrerActions";

class KopiContainer extends React.Component {
  static propTypes = {
    miljogebyr: PropTypes.object,
    getMiljogebyrKopi: PropTypes.func.isRequired,
  };

  state = {
    showKopiSpinner: false,
  };

  onClickHandler = () => {
    const { miljogebyr, getMiljogebyrKopi } = this.props;
    this.setState(() => ({ showKopiSpinner: true }));
    getMiljogebyrKopi(miljogebyr.id)
      .then(() => this.setState(() => ({ showKopiSpinner: false })))
      .catch(() => this.setState(() => ({ showKopiSpinner: false })));
  };

  render() {
    const { showKopiSpinner } = this.state;
    return (
      <LoadButton
        isLoading={showKopiSpinner}
        buttonProps={{
          className: "btn btn-bym-standard",
          style: { marginRight: 20 },
          onClick: this.onClickHandler,
          disabled: showKopiSpinner,
        }}
        spinnerProps={{ pColor: "#ffffff", sColor: "#4d4d4d" }}
      >
        <FontAwesomeIcon
          icon={faEye}
          fixedWidth
          style={{ paddingRight: "5px" }}
        />
        <span>Forhåndsvis ettersendelse</span>
      </LoadButton>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    miljogebyr: state.miljogebyr,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getMiljogebyrKopi: (miljogebyrId) =>
      dispatch(getMiljogebyrKopiAction(miljogebyrId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(KopiContainer);
