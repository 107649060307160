import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import LoadButton from "../../LoadButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { getIleggelseKopi as getIleggelseKopiAction } from "../../../../actions/ileggelserActions";

class KopiContainer extends React.Component {
  static propTypes = {
    ileggelse: PropTypes.object,
    getIleggelseKopi: PropTypes.func.isRequired,
  };

  state = {
    showKopiAvIleggelseSpinner: false,
  };

  onClickHandler = () => {
    const { ileggelse, getIleggelseKopi } = this.props;
    this.setState(() => ({ showKopiAvIleggelseSpinner: true }));
    getIleggelseKopi(ileggelse.id)
      .then(() => this.setState(() => ({ showKopiAvIleggelseSpinner: false })))
      .catch(() =>
        this.setState(() => ({ showKopiAvIleggelseSpinner: false }))
      );
  };

  render() {
    const { showKopiAvIleggelseSpinner } = this.state;
    return (
      <LoadButton
        isLoading={showKopiAvIleggelseSpinner}
        buttonProps={{
          className: "btn btn-bym-standard",
          style: { marginRight: 20 },
          onClick: this.onClickHandler,
          disabled: showKopiAvIleggelseSpinner,
        }}
        spinnerProps={{ pColor: "#ffffff", sColor: "#4d4d4d" }}
      >
        <FontAwesomeIcon
          icon={faEye}
          fixedWidth
          style={{ paddingRight: "5px" }}
        />
        <span>Forhåndsvis ettersendelse</span>
      </LoadButton>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ileggelse: state.ileggelse,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getIleggelseKopi: (ileggelseId) =>
      dispatch(getIleggelseKopiAction(ileggelseId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(KopiContainer);
