import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import CloseButton from "../CloseButton";
import Table from "../Tables/BaseTable";

import { getBrevstatistikkHistorikk } from "../../../actions/brevstatistikkActions";
import getSvarUtHistorikkCloseUrl from "../../../utils/getSvarUtHistorikkCloseUrlUtils";
import { useHistory } from "react-router-dom";

const styles = {
  topHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: 50,
    paddingRight: 50,
    marginBottom: 41,
  },
};

const renderRow = (brevstatistikkHistorikk) => {
  return brevstatistikkHistorikk.map((bh, index) => (
    <tr key={`brevstatistikkHistorikkTableBodyTr${index}`}>
      <td>{bh.tidspunkt}</td>
      <td>{bh.hendelse}</td>
    </tr>
  ));
};

const headerRow = ["Tidpunkt", "Hendelse"];

const SvarUtBrevHistorikk = ({ computedMatch }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [brevstatistikkHistorikk, setBrevstatistikkHistorikk] = useState([]);

  useEffect(() => {
    const fetchBrevstatistikkHistorikk = async () => {
      if (computedMatch && computedMatch.params) {
        const result = await dispatch(
          getBrevstatistikkHistorikk(computedMatch.params.svarUtId)
        );
        if (result) {
          setBrevstatistikkHistorikk(result.reverse());
        }
      }
    };

    fetchBrevstatistikkHistorikk();
  }, [computedMatch, dispatch]);

  const handleCloseClick = () => {
    if (computedMatch && computedMatch.params) {
      history.push(getSvarUtHistorikkCloseUrl(computedMatch.params.id));
    }
  };

  return (
    <section style={{ paddingRight: 50, paddingLeft: 50 }}>
      <div style={styles.topHeader}>
        <div className="pull-left">
          <h1>Svar Ut Historikk</h1>
        </div>
        <div className="pull-right">
          <CloseButton
            customHandleClick={handleCloseClick}
            text={"Lukk Svar Ut historikk"}
          />
        </div>
      </div>
      <div className="clearfix" />
      <Table
        id="svar-ut-historikk-liste"
        headerRow={headerRow}
        defaultStyle
        striped
      >
        <tbody>{renderRow(brevstatistikkHistorikk)}</tbody>
      </Table>
    </section>
  );
};

export default SvarUtBrevHistorikk;
