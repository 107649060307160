import React from "react";
import InnkrevingStatusNavbar from "./InnkrevingStatusNavbar.jsx";
import InnkrevingIleggelserData from "./InnkrevingIleggelserData";
import InnkrevingIleggelserVedtakData from "./InnkrevingIleggelserVedtakData";

import {
  getIleggelserStoppetTitle,
  getIleggelserStoppetHeaders,
  getIleggelserVenterTitle,
  getIleggelserVenterHeaders,
  getIleggelserVellykketTitle,
  getIleggelserVellykketHeaders,
  getIleggelserFeiletHeaders,
  getIleggelserFeiletTitle,
  getIleggelserBehandlesInnkrevingHeaders,
  getIleggelserBehandlesInnkrevingTitle,
  getIleggelserKlarHeaders,
  getIleggelserKlarTitle,
} from "./InnkrevingIleggelserDataHelpers";

import sitePaths from "../../constants/sitePaths";
import { Route, Switch } from "react-router";
import {
  getIleggelserVedtakBehandlesInnkrevingHeaders,
  getIleggelserVedtakBehandlesInnkrevingTitle,
  getIleggelserVedtakFeiletHeaders,
  getIleggelserVedtakFeiletTitle,
  getIleggelserVedtakKlarHeaders,
  getIleggelserVedtakKlarTitle,
  getIleggelserVedtakStoppetHeaders,
  getIleggelserVedtakStoppetTitle,
  getIleggelserVedtakVellykketHeaders,
  getIleggelserVedtakVellykketTitle,
  getIleggelserVedtakVenterHeaders,
  getIleggelserVedtakVenterTitle,
} from "./InnkrevingIleggelserVedtakDataHelpers.js";

const InnkrevingIleggelser = ({ reportData, isLoading }) => {
  const statusNavbarItems = [
    {
      name: "Venter i fagsystemet",
      path: sitePaths.innkreving.ileggelser.venter.shortUrl,
      title: "Venter i fagsystemet - Ileggelser - Innkreving",
    },
    {
      name: "Stoppet av fagsystemet",
      path: sitePaths.innkreving.ileggelser.stoppet.shortUrl,
      title: "Stoppet av fagsystemet - Ileggelser - Innkreving",
    },
    {
      name: "Klar til Innkreving",
      path: sitePaths.innkreving.ileggelser.klar.shortUrl,
      title: "Klar til Innkreving - Ileggelser - Innkreving",
    },
    {
      name: "Behandles av Innkreving",
      path: sitePaths.innkreving.ileggelser.behandlesInnkreving.shortUrl,
      title: "Behandles av Innkreving - Ileggelser - Innkreving",
    },
    {
      name: "Feil fra Innkreving",
      path: sitePaths.innkreving.ileggelser.feilet.shortUrl,
      title: "Feil fra Innkreving - Ileggelser - Innkreving",
    },
    {
      name: "Vellykket hos Innkreving (siste 100)",
      path: sitePaths.innkreving.ileggelser.vellykket.shortUrl,
      title: "Vellykket hos Innkreving (siste 100) - Ileggelser - Innkreving",
    },
  ];

  return (
    <>
      <InnkrevingStatusNavbar items={statusNavbarItems} />
      <section style={{ paddingRight: 50, paddingLeft: 50 }}>
        <h1>Innkreving - Ileggelser</h1>
        <Switch>
          <Route exact path={sitePaths.innkreving.ileggelser.venter.shortUrl}>
            <>
              <div
                style={{
                  marginTop: 50,
                  marginBottom: 50,
                }}
              >
                <InnkrevingIleggelserData
                  id="ileggelseInTimeQuarantineList"
                  title={getIleggelserVenterTitle(reportData)}
                  headers={getIleggelserVenterHeaders}
                  ileggelser={
                    reportData && reportData.a_IleggelseInTimeQuarantineList
                  }
                  isLoading={isLoading}
                />
              </div>
              <div>
                <InnkrevingIleggelserVedtakData
                  id="ileggelseVedtakInTimeQuarantineList"
                  title={getIleggelserVedtakVenterTitle(reportData)}
                  headers={getIleggelserVedtakVenterHeaders}
                  vedtak={
                    reportData &&
                    reportData.a_IleggelseVedtakInTimeQuarantineList
                  }
                  isLoading={isLoading}
                />
              </div>
            </>
          </Route>
          <Route exact path={sitePaths.innkreving.ileggelser.stoppet.shortUrl}>
            <>
              <div
                style={{
                  marginTop: 50,
                  marginBottom: 50,
                }}
              >
                <InnkrevingIleggelserData
                  id="ileggelseStoppetAvHgkSystemetList"
                  title={getIleggelserStoppetTitle(reportData)}
                  headers={getIleggelserStoppetHeaders}
                  ileggelser={
                    reportData && reportData.b_IleggelseStoppetAvHgkSystemetList
                  }
                  isLoading={isLoading}
                  inkludFail
                />
              </div>
              <div>
                <InnkrevingIleggelserVedtakData
                  id="ileggelseVedtakStoppetAvHgkSystemetList"
                  title={getIleggelserVedtakStoppetTitle(reportData)}
                  headers={getIleggelserVedtakStoppetHeaders}
                  vedtak={
                    reportData &&
                    reportData.b_IleggelseVedtakStoppetAvHgkSystemetList
                  }
                  isLoading={isLoading}
                  inkludFail
                />
              </div>
            </>
          </Route>
          <Route exact path={sitePaths.innkreving.ileggelser.klar.shortUrl}>
            <>
              <div
                style={{
                  marginTop: 50,
                  marginBottom: 50,
                }}
              >
                <InnkrevingIleggelserData
                  id="ileggelseWillBeSentList"
                  title={getIleggelserKlarTitle(reportData)}
                  headers={getIleggelserKlarHeaders}
                  ileggelser={
                    reportData && reportData.c_IleggelseWillBeSentList
                  }
                  isLoading={isLoading}
                />
              </div>
              <div>
                <InnkrevingIleggelserVedtakData
                  id="ileggelseVedtakWillBeSentList"
                  title={getIleggelserVedtakKlarTitle(reportData)}
                  headers={getIleggelserVedtakKlarHeaders}
                  vedtak={
                    reportData && reportData.c_IleggelseVedtakWillBeSentList
                  }
                  isLoading={isLoading}
                />
              </div>
            </>
          </Route>
          <Route
            exact
            path={sitePaths.innkreving.ileggelser.behandlesInnkreving.shortUrl}
          >
            <>
              <div
                style={{
                  marginTop: 50,
                  marginBottom: 50,
                }}
              >
                <InnkrevingIleggelserData
                  id="ileggelseInIneQueueList"
                  title={getIleggelserBehandlesInnkrevingTitle(reportData)}
                  headers={getIleggelserBehandlesInnkrevingHeaders}
                  ileggelser={
                    reportData && reportData.d_IleggelseInIneQueueList
                  }
                  isLoading={isLoading}
                  inkludSentIneDato={true}
                />
              </div>
              <div>
                <InnkrevingIleggelserVedtakData
                  id="ileggelseVedtakInIneQueueList"
                  title={getIleggelserVedtakBehandlesInnkrevingTitle(
                    reportData
                  )}
                  headers={getIleggelserVedtakBehandlesInnkrevingHeaders}
                  vedtak={
                    reportData && reportData.d_IleggelseVedtakInIneQueueList
                  }
                  isLoading={isLoading}
                  inkludSentIneDato={true}
                />
              </div>
            </>
          </Route>
          <Route exact path={sitePaths.innkreving.ileggelser.feilet.shortUrl}>
            <>
              <div
                style={{
                  marginTop: 50,
                  marginBottom: 50,
                }}
              >
                <InnkrevingIleggelserData
                  id="ileggelseHasBeenRejectedList"
                  title={getIleggelserFeiletTitle(reportData)}
                  headers={getIleggelserFeiletHeaders}
                  ileggelser={
                    reportData && reportData.f_IleggelseHasBeenRejectedList
                  }
                  inkludFail
                  isLoading={isLoading}
                  inkludBehandletIneDato={true}
                />
              </div>
              <div>
                <InnkrevingIleggelserVedtakData
                  id="ileggelseVedtakHasBeenRejectedList"
                  title={getIleggelserVedtakFeiletTitle(reportData)}
                  headers={getIleggelserVedtakFeiletHeaders}
                  vedtak={
                    reportData &&
                    reportData.f_IleggelseVedtakHasBeenRejectedList
                  }
                  inkludFail
                  isLoading={isLoading}
                  inkludBehandletIneDato={true}
                />
              </div>
            </>
          </Route>
          <Route
            exact
            path={sitePaths.innkreving.ileggelser.vellykket.shortUrl}
          >
            <>
              <div
                style={{
                  marginTop: 50,
                  marginBottom: 50,
                }}
              >
                <InnkrevingIleggelserData
                  id="ileggelseHasBeenAccepted_last_limit_100_List"
                  title={getIleggelserVellykketTitle(reportData)}
                  headers={getIleggelserVellykketHeaders}
                  ileggelser={
                    reportData &&
                    reportData.e_IleggelseHasBeenAccepted_last_limit_100_List
                  }
                  isLoading={isLoading}
                  inkludBehandletIneDato={true}
                />
              </div>
              <div>
                <InnkrevingIleggelserVedtakData
                  id="ileggelseVedtakHasBeenAccepted_last_limit_100_List"
                  title={getIleggelserVedtakVellykketTitle(reportData)}
                  headers={getIleggelserVedtakVellykketHeaders}
                  vedtak={
                    reportData &&
                    reportData.e_IleggelseVedtakHasBeenAccepted_last_limit_100_List
                  }
                  isLoading={isLoading}
                  inkludBehandletIneDato={true}
                />
              </div>
            </>
          </Route>
        </Switch>
      </section>
    </>
  );
};

export default InnkrevingIleggelser;
