import React, { Component } from "react";
import PropTypes from "prop-types";
import { HeaderCell } from "../../Common/Tables";
import Table from "../../Common/SortableTableContainer";
import { Link } from "react-router-dom";

import { ileggelseClientSpecificUtils } from "../../../utils/ileggelseClientSpecificUtils";
import { toNorwegianDatetime } from "../../../utils/datetimeUtils";
import { authorized } from "../../../utils/authorizationUtils";
import { bypatruljen, juridiskArray } from "../../../constants/roles";
import sitePaths from "../../../constants/sitePaths";

import { ReactComponent as IconImageWhite } from "../../../images/icon-image-white.svg";
import { ReactComponent as IconImage } from "../../../images/icon-image.svg";

class List extends Component {
  static propTypes = {
    ileggelser: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        ileggelsesnummer: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
        ]),
        ileggelsesdatoFra: PropTypes.string,
        tjenestenummer: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
        ]),
        internkode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        kjennemerke: PropTypes.string,
        gatenavn: PropTypes.string,
        type: PropTypes.string,
        bilder: PropTypes.array,
      })
    ),
  };

  renderRow = (ileggelse) => {
    let bild =
      ileggelse.antallBilder > 1 ? (
        ileggelse.isMakulert ? (
          <IconImageWhite />
        ) : (
          <IconImage />
        )
      ) : (
        <span />
      );

    return (
      <tr
        key={`ileggelserTableBodyTr${ileggelse.id}`}
        style={
          ileggelse.isMakulert === true
            ? { backgroundColor: "#d32316", color: "white" }
            : {}
        }
      >
        <td data-testid={`ileggelsesnummer-${ileggelse.ileggelsesnummer}`}>
          {ileggelse.isMakulert && (
            <Link
              data-testid={`${ileggelse.ileggelsesnummer}-detalj-link`}
              to={`${sitePaths.ileggelser.oversikt.shortUrl}/${ileggelse.id}`}
              style={{ textDecoration: "none" }}
            >
              <span className="link-makulert">
                {ileggelse.ileggelsesnummer}
              </span>
            </Link>
          )}
          {!ileggelse.isMakulert && (
            <Link
              to={`${sitePaths.ileggelser.oversikt.shortUrl}/${ileggelse.id}`}
              className="underline"
            >
              <span>{ileggelse.ileggelsesnummer}</span>
            </Link>
          )}
        </td>
        <td>
          {ileggelse.ileggelsesdatoFra &&
            toNorwegianDatetime(ileggelse.ileggelsesdatoTil).format(
              "DD.MM.YYYY"
            )}
        </td>
        <td>
          {ileggelse.ileggelsesdatoFra &&
            toNorwegianDatetime(ileggelse.ileggelsesdatoFra).format(
              "HH:mm"
            )}{" "}
          {ileggelse.ileggelsesdatoTil &&
            ` - ${toNorwegianDatetime(ileggelse.ileggelsesdatoTil).format(
              "HH:mm"
            )}`}
        </td>
        <td>{ileggelse.tjenestenummer}</td>
        <td>
          {ileggelseClientSpecificUtils.getOvertredelserString(
            ileggelse.overtredelse1,
            ileggelse.overtredelse2,
            ileggelse.overtredelse3
          )}
        </td>
        <td>{ileggelse.kjennemerke}</td>
        <td>{ileggelse.nasjon}</td>
        <td>{ileggelse.gatenavn}</td>
        <td>
          {(ileggelse.gatenummer || "") + " " + (ileggelse.oppgang || "")}
        </td>
        <td>{ileggelse.vedGate}</td>
        <td>{ileggelse.vedGatenummer}</td>
        <td>{ileggelse.bydel}</td>
        {authorized([...juridiskArray, bypatruljen.saksbehandleradmin]) && (
          <React.Fragment>
            <td>{ileggelse.klageKodeList}</td>
            <td>{ileggelse.vedtak && ileggelse.vedtak.saksbehandler}</td>
          </React.Fragment>
        )}
        <td>{ileggelse.type}</td>
        <td>{bild}</td>
      </tr>
    );
  };

  getTableHeaders = () => (
    <React.Fragment>
      <HeaderCell>Ilegg.nr.</HeaderCell>
      <HeaderCell>Ilegg.dato</HeaderCell>
      <HeaderCell>Klokkeslett</HeaderCell>
      <HeaderCell>Tjenestenr.</HeaderCell>
      <HeaderCell>Overt.kode</HeaderCell>
      <HeaderCell>Kj.merke</HeaderCell>
      <HeaderCell>Nasjon</HeaderCell>
      <HeaderCell>Gatenavn</HeaderCell>
      <HeaderCell>Gatenr.</HeaderCell>
      <HeaderCell>Ved</HeaderCell>
      <HeaderCell>Ved nr.</HeaderCell>
      <HeaderCell>Bydel</HeaderCell>
      {authorized([...juridiskArray, bypatruljen.saksbehandleradmin]) && (
        <React.Fragment>
          <HeaderCell>Klagekode</HeaderCell>
          <HeaderCell>Saksbehandler</HeaderCell>
        </React.Fragment>
      )}
      <HeaderCell>Type</HeaderCell>
      <HeaderCell>Bilde</HeaderCell>
    </React.Fragment>
  );

  render() {
    const { ileggelser } = this.props;

    return (
      <Table
        id="ileggelse-liste"
        storeKey="ileggelser"
        items={ileggelser}
        renderRow={this.renderRow}
      >
        {this.getTableHeaders()}
      </Table>
    );
  }
}

export default List;
