import { GET_ARKIVSAK_SAK } from "../actions/arkivsakAction";

export const arkivsakReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ARKIVSAK_SAK:
      return action.payload;
    default:
      return state;
  }
};

export default arkivsakReducer;
