import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import _ from "lodash";

import { HeaderCell } from "../../Common/Tables/index";
import Table from "../../Common/Tables/Table";
import { toNorwegianDatetime } from "../../../utils/datetimeUtils";
import { miljogebyrStatuser } from "../../../constants/globals";
import sitePaths from "../../../constants/sitePaths";

import { ReactComponent as IconImageWhite } from "../../../images/icon-image-white.svg";
import { ReactComponent as IconImage } from "../../../images/icon-image.svg";

class List extends Component {
  static propTypes = {
    miljøgebyrer: PropTypes.arrayOf(PropTypes.shape({})),
    overtredelseskoderMiljogebyr: PropTypes.arrayOf(PropTypes.object),
    setSort: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    count: PropTypes.number.isRequired,
    miljogebyrerOversiktFilterForm: PropTypes.object.isRequired,
    getMiljogebyrer: PropTypes.func.isRequired,
  };

  componentDidMount = () => {
    const { setSort } = this.props;
    // setSort is set without any new actions taking place due to getMiljogebyrer
    // in OversiktContainer where data comes in sorted with ileggelsedatoTil desc
    setSort({
      cellKey: "ileggelsesdatoTil",
      orders: "desc",
    });
  };

  renderRow = (miljogebyr) => {
    const statusnavn = {};
    miljogebyrStatuser.forEach(
      (status) => (statusnavn[status.id] = status.navn)
    );

    if (miljogebyr) {
      let statusArray = [];

      if (miljogebyr.isTilEttersendelse)
        statusArray.push(statusnavn["isTilEttersendelse"]);
      if (miljogebyr.isEttersendt) statusArray.push(statusnavn["isEttersendt"]);
      if (miljogebyr.isKlarTilKemner)
        statusArray.push(statusnavn["isKlarTilIne"]);
      if (miljogebyr.isStoppetAvSystemet)
        statusArray.push(statusnavn["isStoppetAvSystemet"]);
      if (miljogebyr.isFeiletKemner)
        statusArray.push(statusnavn["isFeiletIne"]);
      if (miljogebyr.isSendtKemner) statusArray.push(statusnavn["isSendtIne"]); // Skal stå miljogebyr.isSendtKemner tils backend er endret til miljogebyr.isSendtIne
      if (miljogebyr.isBetalt) statusArray.push(statusnavn["isBetalt"]);
      if (miljogebyr.isMaKvalitetsikres)
        statusArray.push(statusnavn["isMaKvalitetsikres"]);
      if (miljogebyr.isVenter) statusArray.push("Venter");
      if (miljogebyr.isMakulert) statusArray.push("Makulert");

      let statuserElement = "";
      if (statusArray.length > 0) {
        statuserElement = (
          <React.Fragment>
            {statusArray.map((status, index) => {
              return (
                <React.Fragment key={`reactFragmentStatus${index}`}>
                  <span className="badge-bym-gray" key={`statusBadge${index}`}>
                    {status}
                  </span>
                  &nbsp;
                </React.Fragment>
              );
            })}
          </React.Fragment>
        );
      }

      let bild =
        miljogebyr.antallBilder > 1 ? (
          miljogebyr.isMakulert ? (
            <IconImageWhite />
          ) : (
            <IconImage />
          )
        ) : (
          <span />
        );
      return (
        <tr
          key={`ileggelserTableBodyTr${miljogebyr.id}`}
          style={
            miljogebyr.isMakulert
              ? { backgroundColor: "#d32316", color: "white" }
              : {}
          }
        >
          <td data-testid={`ileggelsesnummer-${miljogebyr.ileggelsesnummer}`}>
            {miljogebyr.isMakulert && (
              <Link
                to={`${sitePaths.miljogebyrer.oversikt.shortUrl}/${miljogebyr.id}`}
                style={{ textDecoration: "none" }}
              >
                <span className="link-makulert">
                  {miljogebyr.ileggelsesnummer}
                </span>
              </Link>
            )}
            {!miljogebyr.isMakulert && (
              <Link
                to={`${sitePaths.miljogebyrer.oversikt.shortUrl}/${miljogebyr.id}`}
                className="underline"
              >
                <span>{miljogebyr.ileggelsesnummer}</span>
              </Link>
            )}
          </td>
          <td>
            {miljogebyr.ileggelsesdatoTil &&
              toNorwegianDatetime(miljogebyr.ileggelsesdatoTil).format(
                "DD.MM.YYYY"
              )}
          </td>
          <td>
            {miljogebyr.ileggelsesdatoFra &&
              toNorwegianDatetime(miljogebyr.ileggelsesdatoFra).format("HH:mm")}
            {miljogebyr.ileggelsesdatoTil &&
              ` - ${toNorwegianDatetime(miljogebyr.ileggelsesdatoTil).format(
                "HH:mm"
              )}`}
          </td>
          <td>{miljogebyr.betjentNummer}</td>
          <td>
            {miljogebyr.paragrafer &&
              miljogebyr.paragrafer.map((p, index) => (
                <span key={index}>
                  {p}
                  <br />
                </span>
              ))}
          </td>
          <td>{miljogebyr.mottaker}</td>
          <td>{miljogebyr.gatenavn}</td>
          <td>
            {(miljogebyr.gatenummer || "") + " " + (miljogebyr.oppgang || "")}
          </td>
          <td>{miljogebyr.vedGate}</td>
          <td>{miljogebyr.vedGatenummer}</td>
          <td>{miljogebyr.gnr}</td>
          <td>{miljogebyr.bnr}</td>
          <td>{statuserElement}</td>
          <td>{bild}</td>
        </tr>
      );
    }
  };

  addOvertredelseParagrafer = (miljogebyr) => {
    const paragrafer = [];
    [
      miljogebyr.overtredelse1,
      miljogebyr.overtredelse2,
      miljogebyr.overtredelse3,
    ].forEach((overtredelse) => {
      if (overtredelse) {
        const overtredelsesKode = _.find(
          this.props.overtredelseskoderMiljogebyr,
          (overtredelsesKode) => overtredelsesKode.id === overtredelse
        );
        if (overtredelsesKode) {
          paragrafer.push(overtredelsesKode.paragraf);
        }
      }
    });

    miljogebyr.paragrafer = paragrafer;

    return miljogebyr;
  };

  onSort = (sort) => {
    const {
      setSort,
      miljogebyrerOversiktFilterForm,
      page,
      count,
      getMiljogebyrer,
    } = this.props;

    setSort(sort);

    getMiljogebyrer(
      {
        ...miljogebyrerOversiktFilterForm,
      },
      page,
      count,
      sort
    );
  };

  getTableHeaders = (sort) => (
    <React.Fragment>
      <HeaderCell>Ilegg.nr.</HeaderCell>
      <HeaderCell cellKey="ileggelsesdatoTil" sort={sort} onSort={this.onSort}>
        Ilegg.dato
      </HeaderCell>
      <HeaderCell>Klokkeslett</HeaderCell>
      <HeaderCell cellKey="tjenestenummer" sort={sort} onSort={this.onSort}>
        Tjenestenr.
      </HeaderCell>
      <HeaderCell>Overt.kode</HeaderCell>
      <HeaderCell>Mottaker</HeaderCell>
      <HeaderCell cellKey="gatenavn" sort={sort} onSort={this.onSort}>
        Gatenavn
      </HeaderCell>
      <HeaderCell>Gatenr.</HeaderCell>
      <HeaderCell>Ved</HeaderCell>
      <HeaderCell>Ved nr.</HeaderCell>
      <HeaderCell>GNR</HeaderCell>
      <HeaderCell>BNR</HeaderCell>
      <HeaderCell>Status</HeaderCell>
      <HeaderCell>Bilde</HeaderCell>
    </React.Fragment>
  );

  render() {
    const { miljøgebyrer, overtredelseskoderMiljogebyr, sort } = this.props;
    const miljøgebyrerWithOvertredelsesKoder = miljøgebyrer;
    if (miljøgebyrer && overtredelseskoderMiljogebyr)
      miljøgebyrer.map(this.addOvertredelseParagrafer);

    return (
      <Table
        id="miljogebyr-liste"
        items={miljøgebyrerWithOvertredelsesKoder}
        renderRow={this.renderRow}
      >
        {this.getTableHeaders(sort)}
      </Table>
    );
  }
}

export default List;
