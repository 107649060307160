import axios from "../services/axios";
import * as api from "../constants/api";
import { errorMessage, fackePromis } from "../utils/axiosUtils";

export const GET_ARKIVSAK_SAK = "GET_ARKIVSAK_SAK";

export const getArkivsakSak = (saksnummerObject, cleanstate = true) => {
  return async (dispatch) => {
    cleanstate && dispatch(cleanUpAllKlageSidenState);
    const url = api.GET_ARKIVSAK_SAK_URL(
      saksnummerObject.year,
      saksnummerObject.nummer
    );
    return axios
      .get(url)
      .then((response) => {
        const result = response.data.result;
        dispatch({ type: GET_ARKIVSAK_SAK, payload: result });
        return result;
      })
      .catch(() => {
        errorMessage(dispatch, "Saksnummer finnes ikke.", GET_ARKIVSAK_SAK);
        return null;
      });
  };
};

export const nullStilleKlageSidenState = () => {
  return (dispatch) =>
    fackePromis("Tilbaksetiller  kalgesiden").then(() => {
      cleanUpAllKlageSidenState(dispatch);
    });
};

const cleanUpAllKlageSidenState = (dispatch) => {
  dispatch({ type: "GET_ARKIVSAK_SAK", payload: {} });
  dispatch({ type: "GET_SAK_DOKUMENTER", payload: {} });
  dispatch({ type: "GET_KLAGE_ILEGGELSE", payload: {} });
  dispatch({ type: "GET_ALL_KLAGE_ILEGGELSER_SUCCESS", payload: [] });
  dispatch({ type: "GET_ALL_KLAGE_VEDTAK_SUCCESS", payload: {} });
  dispatch({ type: "GET_ONE_KLAGE_DETAIL_SUCCESS", payload: {} });
  dispatch({ type: "GET_ILEGGELSE_DETAIL", payload: {} });
  dispatch({ type: "KLAGE_ILEGGELSE_IMAGE", payload: [] });
  dispatch({ type: "GET_ILEGGELSE_RETTELSER_SUCCESS", payload: [] });
  dispatch({ type: "GET_MILJO_RETTELSER_SUCCESS", payload: [] });
  return {};
};
