import React from "react";
import Excel from "../../Common/Excel";

import {
  formatDateHavingNorwegianDateTime,
  formatDateTimeHavingNorwegianDateTime,
} from "../../../utils/datetimeUtils";

const MakuleringerExcel = ({
  makuleringerRapport,
  makuleringerSubmittedForm,
}) => {
  return (
    <Excel
      element={(onClick) => (
        <button className="btn btn-bym-medium btn-bym-action" onClick={onClick}>
          Lag rapport
        </button>
      )}
      filename={"Makuleringer.xlsx"}
      sheets={[
        {
          name: "Makuleringer",
          data: makuleringerRapport.items.map((m) => ({
            Ileggelsesdato: m.ileggelsesdatoTil
              ? formatDateHavingNorwegianDateTime(m.ileggelsesdatoTil)
              : "",
            Ileggelsesnummer: m.ileggelsesnummer,
            Tjenestenummer: m.tjenestenummer,
            Makuleringsgrunn: m.kommentar,
            "Makulert av": m.makulertAvBetjentTjenestenummerOrOsloFellseIdent,
            "Makulert dato": m.makuleringOpprettetDato
              ? formatDateHavingNorwegianDateTime(m.makuleringOpprettetDato)
              : "",
          })),
        },
        {
          name: "Søkdata",
          data: [
            {
              "Timestamp for søk": formatDateTimeHavingNorwegianDateTime(
                makuleringerSubmittedForm.submitTimestamp
              ),
              "Makulert dato fra": formatDateTimeHavingNorwegianDateTime(
                makuleringerSubmittedForm.makuleringFraDato
              ),
              "Makulert dato til": formatDateTimeHavingNorwegianDateTime(
                makuleringerSubmittedForm.makuleringTilDato
              ),
              "Ileggelsesdato fra": formatDateTimeHavingNorwegianDateTime(
                makuleringerSubmittedForm.ileggelseFraDato
              ),
              "Ileggelsesdato til": formatDateTimeHavingNorwegianDateTime(
                makuleringerSubmittedForm.ileggelseTilDato
              ),
            },
          ],
        },
      ]}
      total={makuleringerRapport.total}
      count={makuleringerRapport.count}
    />
  );
};

export default MakuleringerExcel;
