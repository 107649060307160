import React, { Component } from "react";
import { connect } from "react-redux";
import { isEmpty } from "lodash";

import KlageInfoDetail from "./KlageInfoDetail";
import Dokumenter from "./Dokumenter";
import Spinner from "../Common/Spinner";

export class KlageInfoContainer extends Component {
  state = { isLoading: false };

  render() {
    const { sak } = this.props;
    const { isLoading } = this.state;

    if (isEmpty(sak)) return null;

    return (
      <div className="klageDetailTabs">
        <div className="overskrift">KlageInfo</div>
        <div style={{ marginTop: 20 }}>
          <KlageInfoDetail sak={sak} />
          <Dokumenter sak={sak} />
          <Spinner isLoading={isLoading} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  klageImages: state.klageeImage,
  sak: state.sak,
});

export default connect(mapStateToProps)(KlageInfoContainer);
