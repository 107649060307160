import PropTypes from "prop-types";
import React, { Component } from "react";
import map from "lodash/map";
import isEmpty from "lodash/isEmpty";

export default class Dokumenter extends Component {
  static propTypes = {
    sak: PropTypes.object,
  };
  render() {
    const { sak } = this.props;

    return (
      <div className="klagevedleggDiv">
        <div
          className="fake-label overskrift"
          style={{ fontSize: 16, fontWeight: "bold" }}
        >
          Vedlegg
        </div>
        {sak && !isEmpty(sak.dokumenter) && (
          <ul>
            {map(sak.dokumenter, (i) => (
              <li key={i.dokumentNummer} style={{ lineHeight: "1.5rem" }}>
                {i.dokumentNummer + " : " + i.dokumentTittel}
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  }
}
