import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Route, withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { Col, Container, Row } from "react-bootstrap";

import CreateModals from "./CreateModals/CreateModals";
import DropDown from "../Common/Forms/DropDown";

import GrunndataFilterContainer from "./GrunndataFilterContainer";
import ExportToExcelContainer from "./ExportToExcelContainer";

import AktorerList from "./Lists/AktorerList";
import BetjentList from "./Lists/BybetjentList";
import FargerList from "./Lists/FargerList";
import ForsinketMakuleringList from "./Lists/ForsinketMakuleringList";
import InternkoderList from "./Lists/InterkoderList";
import KjoretoyTyperList from "./Lists/KjoretoyTyperList";
import KjoretoyMerkerList from "./Lists/KjoretoyMerkerList";
import NasjonerList from "./Lists/NasjonerList";
import OvertredelseskoderIleggelseList from "./Lists/OvertredelseskoderIleggelseList";
import OvertredelseskoderMiljogebyrList from "./Lists/OvertredelseskoderMiljogebyrList";
import RettekoderList from "./Lists/RettekoderList";
import VedtakskoderList from "./Lists/VedtakskoderList";

import { getInternkoderIleggelser } from "../../actions/internkoderIleggelserActions";
import { getInternkoderMiljogebyr } from "../../actions/internkoderMiljogebyrActions";
import { getOvertredelsegrupper } from "../../actions/overtredelsegrupperActions";
import { getIleggelsestyper } from "../../actions/ileggelsestyperActions";
import { getKlageKoder } from "../../actions/klagekoderAction";
import { authorized } from "../../utils/authorizationUtils";
import {
  bypatruljen,
  bypatruljenArray,
  juridisk,
  juridiskArray,
  innsyn,
} from "../../constants/roles";

import sitePaths from "../../constants/sitePaths";
import * as title from "../../constants/titles";

import "./GrunndataPage.css";

class GrunndataPage extends React.Component {
  static propTypes = {
    aktorer: PropTypes.array,
    bybetjenter: PropTypes.array,
    internkoderIleggelser: PropTypes.array,
    internkoderMiljogebyr: PropTypes.array,
    overtredelserkoderIleggelser: PropTypes.array,
    overtredelseskoderMiljogebyr: PropTypes.array,
    ileggelsestyper: PropTypes.array,
    klagekoder: PropTypes.array,
    vedtakskoder: PropTypes.object,

    getBybetjenter: PropTypes.func,
    getInternkoderIleggelser: PropTypes.func,
    getInternkoderMiljogebyr: PropTypes.func,
    getOvertredelsegrupper: PropTypes.func,
    getOvertredelseskoderMiljogebyr: PropTypes.func,
    getIleggelsestyper: PropTypes.func,
    getVedtakskoder: PropTypes.func,
    getKlageKoder: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      valgtGrunndata: "Velg grunndata",
      authorizedAktorer: authorized([bypatruljen.saksbehandleradmin]),
      authorizedBypatruljen: authorized([
        ...bypatruljenArray,
        ...juridiskArray,
        innsyn.seksjonssjefVaktsentralen,
      ]),
      authorizedFarger: authorized([
        ...bypatruljenArray,
        ...juridiskArray,
        innsyn.seksjonssjefVaktsentralen,
      ]),
      authorizedForsinketMakuleringMal: authorized([
        bypatruljen.saksbehandleradmin,
      ]),
      authorizedIleggelseOvertredelseskoder: authorized([
        ...bypatruljenArray,
        ...juridiskArray,
        innsyn.serviceavdelingVaktsentralen,
        innsyn.vaktsentralen,
        innsyn.serviceavdeling,
        innsyn.seksjonssjefVaktsentralen,
      ]),
      authorizedKjoretoyTyper: authorized([
        ...bypatruljenArray,
        ...juridiskArray,
        innsyn.seksjonssjefVaktsentralen,
      ]),
      authorizedKjoretoyMerker: authorized([
        ...bypatruljenArray,
        ...juridiskArray,
        innsyn.seksjonssjefVaktsentralen,
      ]),
      authorizedMiljogebyrOvertredelseskoder: authorized([
        ...bypatruljenArray,
        ...juridiskArray,
        innsyn.serviceavdelingVaktsentralen,
        innsyn.vaktsentralen,
        innsyn.serviceavdeling,
        innsyn.seksjonssjefVaktsentralen,
      ]),
      authorizedIleggelseInternkoder: authorized([
        ...bypatruljenArray,
        ...juridiskArray,
        innsyn.serviceavdelingVaktsentralen,
        innsyn.vaktsentralen,
        innsyn.serviceavdeling,
        innsyn.seksjonssjefVaktsentralen,
      ]),
      authorizedMiljogebyrInternkoder: authorized([
        ...bypatruljenArray,
        ...juridiskArray,
        innsyn.serviceavdelingVaktsentralen,
        innsyn.vaktsentralen,
        innsyn.serviceavdeling,
        innsyn.seksjonssjefVaktsentralen,
      ]),
      authorizedNasjoner: authorized([
        ...bypatruljenArray,
        ...juridiskArray,
        innsyn.seksjonssjefVaktsentralen,
      ]),
      authorizedRettekoder: authorized([
        ...bypatruljenArray,
        ...juridiskArray,
        innsyn.seksjonssjefVaktsentralen,
      ]),
      authorizedVedtakskoder: authorized([
        bypatruljen.saksbehandleradmin,
        bypatruljen.avdelingdirektor,
        bypatruljen.seksjonssjefStab,
        bypatruljen.seksjonssjef,
        ...juridiskArray,
        innsyn.serviceavdelingVaktsentralen,
        innsyn.vaktsentralen,
        innsyn.serviceavdeling,
        innsyn.seksjonssjefVaktsentralen,
      ]),
      authorizedToCreateAndEdit: false,
      innerWidth: window.innerWidth,
    };
  }

  componentDidMount() {
    document.title = title.grunndata;
    this.props.getInternkoderIleggelser();
    this.props.getInternkoderMiljogebyr();
    this.props.getOvertredelsegrupper();
    this.props.getIleggelsestyper();
    this.props.getKlageKoder();

    let valgtGrunndata = "Velg grunndata";
    if (
      this.props.location &&
      this.props.location.pathname === sitePaths.grunndata.aktorer
    ) {
      valgtGrunndata = "Aktører";
    } else if (
      this.props.location &&
      this.props.location.pathname === sitePaths.grunndata.bypatruljen
    ) {
      valgtGrunndata = "Bypatruljen";
    } else if (
      this.props.location &&
      this.props.location.pathname === sitePaths.grunndata.farger
    ) {
      valgtGrunndata = "Farger";
    } else if (
      this.props.location &&
      this.props.location.pathname === sitePaths.grunndata.forsinketMakulering
    ) {
      valgtGrunndata = "Forsinket makulering";
    } else if (
      this.props.location &&
      this.props.location.pathname === sitePaths.grunndata.internkoderIleggelser
    ) {
      valgtGrunndata = "Internkoder ileggelser";
    } else if (
      this.props.location &&
      this.props.location.pathname === sitePaths.grunndata.internkoderMiljogebyr
    ) {
      valgtGrunndata = "Internkoder miljøgebyr";
    } else if (
      this.props.location &&
      this.props.location.pathname === sitePaths.grunndata.kjoretoyTyper
    ) {
      valgtGrunndata = "Kjøretøytyper";
    } else if (
      this.props.location &&
      this.props.location.pathname === sitePaths.grunndata.kjoretoyMerker
    ) {
      valgtGrunndata = "Kjøretøymerker";
    } else if (
      this.props.location &&
      this.props.location.pathname === sitePaths.grunndata.nasjoner
    ) {
      valgtGrunndata = "Nasjoner";
    } else if (
      this.props.location &&
      this.props.location.pathname ===
        sitePaths.grunndata.overtredelseskoderIleggelser
    ) {
      valgtGrunndata = "Overtredelseskoder ileggelser";
    } else if (
      this.props.location &&
      this.props.location.pathname ===
        sitePaths.grunndata.overtredelseskoderMiljogebyr
    ) {
      valgtGrunndata = "Overtredelseskoder miljøgebyr";
    } else if (
      this.props.location &&
      this.props.location.pathname === sitePaths.grunndata.rettekoder
    ) {
      valgtGrunndata = "Rettekoder";
    } else if (
      this.props.location &&
      this.props.location.pathname === sitePaths.grunndata.vedtakskoder
    ) {
      valgtGrunndata = "Vedtakskoder";
    } else {
      valgtGrunndata = "Velg grunndata";
    }
    this.handleSelectOptionGrunndataChange(valgtGrunndata);

    window.addEventListener("resize", this.resizeEventListener);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeEventListener);
  }

  resizeEventListener = () => {
    this.setState(() => ({
      innerWidth: window.innerWidth,
    }));
  };

  handleSelectOptionGrunndataChange = (value) => {
    let authorizedToCreateAndEdit;
    switch (value) {
      case "Aktører":
        authorizedToCreateAndEdit = authorized([
          bypatruljen.saksbehandleradmin,
        ]);
        this.props.history.push(sitePaths.grunndata.aktorer);
        break;
      case "Bypatruljen":
        authorizedToCreateAndEdit = authorized([
          bypatruljen.saksbehandler,
          bypatruljen.saksbehandleradmin,
          bypatruljen.avdelingdirektor,
          bypatruljen.seksjonssjefStab,
        ]);
        this.props.history.push(sitePaths.grunndata.bypatruljen);
        break;
      case "Farger":
        authorizedToCreateAndEdit = authorized([
          bypatruljen.saksbehandleradmin,
        ]);
        this.props.history.push(sitePaths.grunndata.farger);
        break;
      case "Forsinket makulering":
        authorizedToCreateAndEdit = authorized([
          bypatruljen.saksbehandleradmin,
        ]);
        this.props.history.push(sitePaths.grunndata.forsinketMakulering);
        break;
      case "Internkoder ileggelser":
        authorizedToCreateAndEdit = authorized([
          bypatruljen.saksbehandleradmin,
        ]);
        this.props.history.push(sitePaths.grunndata.internkoderIleggelser);
        break;
      case "Internkoder miljøgebyr":
        authorizedToCreateAndEdit = authorized([
          bypatruljen.saksbehandleradmin,
        ]);
        this.props.history.push(sitePaths.grunndata.internkoderMiljogebyr);
        break;
      case "Kjøretøytyper":
        authorizedToCreateAndEdit = authorized([
          bypatruljen.saksbehandleradmin,
        ]);
        this.props.history.push(sitePaths.grunndata.kjoretoyTyper);
        break;
      case "Kjøretøymerker":
        authorizedToCreateAndEdit = authorized([
          bypatruljen.saksbehandleradmin,
        ]);
        this.props.history.push(sitePaths.grunndata.kjoretoyMerker);
        break;
      case "Nasjoner":
        authorizedToCreateAndEdit = authorized([
          bypatruljen.saksbehandleradmin,
        ]);
        this.props.history.push(sitePaths.grunndata.nasjoner);
        break;
      case "Overtredelseskoder ileggelser":
        authorizedToCreateAndEdit = authorized([
          bypatruljen.saksbehandleradmin,
        ]);
        this.props.history.push(
          sitePaths.grunndata.overtredelseskoderIleggelser
        );
        break;
      case "Overtredelseskoder miljøgebyr":
        authorizedToCreateAndEdit = authorized([
          bypatruljen.saksbehandleradmin,
        ]);
        this.props.history.push(
          sitePaths.grunndata.overtredelseskoderMiljogebyr
        );
        break;
      case "Rettekoder":
        authorizedToCreateAndEdit = authorized([
          bypatruljen.saksbehandleradmin,
        ]);
        this.props.history.push(sitePaths.grunndata.rettekoder);
        break;
      case "Vedtakskoder":
        authorizedToCreateAndEdit = authorized([juridisk.ledelse]);
        this.props.history.push(sitePaths.grunndata.vedtakskoder);
        break;
      default:
        authorizedToCreateAndEdit = false;
        break;
    }
    this.setState({
      valgtGrunndata: value,
      authorizedToCreateAndEdit: authorizedToCreateAndEdit,
    });
  };

  getContentStyle = () => {
    const { valgtGrunndata } = this.state;
    if (
      valgtGrunndata === "Farger" ||
      valgtGrunndata === "Kjøretøymerker" ||
      valgtGrunndata === "Kjøretøytyper" ||
      valgtGrunndata === "Nasjoner" ||
      valgtGrunndata === "Rettekoder"
    ) {
      return { paddingRight: 50, paddingLeft: 50, maxWidth: 650 };
    }
    return { paddingRight: 50, paddingLeft: 50 };
  };

  getTableStyle = () => {
    if (window.innerWidth < 768) {
      return { paddingRight: 0, paddingLeft: 0 };
    }
    return this.getContentStyle();
  };

  getDropdownGrunndata = () => {
    let grunndata = [];

    grunndata = this.state.authorizedAktorer
      ? [...grunndata, { id: "Aktører", navn: "Aktører" }]
      : [...grunndata];

    grunndata = this.state.authorizedBypatruljen
      ? [...grunndata, { id: "Bypatruljen", navn: "Bypatruljen" }]
      : [...grunndata];

    grunndata = this.state.authorizedFarger
      ? [...grunndata, { id: "Farger", navn: "Farger" }]
      : [...grunndata];

    grunndata = this.state.authorizedForsinketMakuleringMal
      ? [
          ...grunndata,
          { id: "Forsinket makulering", navn: "Forsinket makulering" },
        ]
      : [...grunndata];

    grunndata = this.state.authorizedIleggelseInternkoder
      ? [
          ...grunndata,
          { id: "Internkoder ileggelser", navn: "Internkoder ileggelser" },
        ]
      : [...grunndata];

    grunndata = this.state.authorizedMiljogebyrInternkoder
      ? [
          ...grunndata,
          { id: "Internkoder miljøgebyr", navn: "Internkoder miljøgebyr" },
        ]
      : [...grunndata];

    grunndata = this.state.authorizedKjoretoyMerker
      ? [...grunndata, { id: "Kjøretøymerker", navn: "Kjøretøymerker" }]
      : [...grunndata];

    grunndata = this.state.authorizedKjoretoyTyper
      ? [...grunndata, { id: "Kjøretøytyper", navn: "Kjøretøytyper" }]
      : [...grunndata];

    grunndata = this.state.authorizedNasjoner
      ? [...grunndata, { id: "Nasjoner", navn: "Nasjoner" }]
      : [...grunndata];

    grunndata = this.state.authorizedIleggelseOvertredelseskoder
      ? [
          ...grunndata,
          {
            id: "Overtredelseskoder ileggelser",
            navn: "Overtredelseskoder ileggelser",
          },
        ]
      : [...grunndata];

    grunndata = this.state.authorizedMiljogebyrOvertredelseskoder
      ? [
          ...grunndata,
          {
            id: "Overtredelseskoder miljøgebyr",
            navn: "Overtredelseskoder miljøgebyr",
          },
        ]
      : [...grunndata];

    grunndata = this.state.authorizedRettekoder
      ? [...grunndata, { id: "Rettekoder", navn: "Rettekoder" }]
      : [...grunndata];

    grunndata = this.state.authorizedVedtakskoder
      ? [...grunndata, { id: "Vedtakskoder", navn: "Vedtakskoder" }]
      : [...grunndata];

    return grunndata;
  };

  render() {
    const { valgtGrunndata } = this.state;

    return (
      <Container fluid>
        <Row>
          <Col>
            <div className="grunndata-fake-nav">
              <Row>
                <Col md={3}>
                  <DropDown
                    items={this.getDropdownGrunndata()}
                    selected={valgtGrunndata}
                    title="Velg grunndata"
                    onChange={(e) =>
                      this.handleSelectOptionGrunndataChange(e.target.value)
                    }
                  />
                </Col>
              </Row>
            </div>
            {this.state.valgtGrunndata &&
              this.state.valgtGrunndata !== "Velg grunndata" && (
                <React.Fragment>
                  <GrunndataFilterContainer location={this.props.location} />
                  <div style={this.getContentStyle()}>
                    <h1
                      className={
                        this.state.innerWidth > 699
                          ? "grunndata-page pull-left"
                          : "grunndata-page"
                      }
                    >
                      {this.state.valgtGrunndata}
                    </h1>
                    <div
                      style={
                        this.state.innerWidth > 699 ? { marginTop: 10 } : {}
                      }
                    >
                      {this.state.authorizedToCreateAndEdit && (
                        <>
                          {this.state.innerWidth > 699 && (
                            <CreateModals
                              createModalToRender={this.state.valgtGrunndata}
                            />
                          )}
                          {this.state.innerWidth < 700 && (
                            <div>
                              <CreateModals
                                createModalToRender={this.state.valgtGrunndata}
                              />
                            </div>
                          )}
                        </>
                      )}
                      {this.state.valgtGrunndata !== "Forsinket makulering" && (
                        <>
                          {this.state.innerWidth > 699 && (
                            <ExportToExcelContainer
                              location={this.props.location}
                              excelClassNames={
                                this.state.innerWidth > 699 ? "pull-right" : ""
                              }
                              excelStyle={
                                this.state.innerWidth > 699
                                  ? { marginRight: 10 }
                                  : { marginTop: 10, marginBottom: 10 }
                              }
                            />
                          )}
                          {this.state.innerWidth < 700 && (
                            <div>
                              <ExportToExcelContainer
                                location={this.props.location}
                                excelClassNames=""
                                excelStyle={{
                                  marginTop: 10,
                                  marginBottom: 10,
                                }}
                              />
                            </div>
                          )}
                        </>
                      )}
                    </div>
                    <div className="clearfix" />
                  </div>
                  <div style={this.getTableStyle()}>
                    {this.state.authorizedAktorer && (
                      <Route
                        path={sitePaths.grunndata.aktorer}
                        render={() => (
                          <AktorerList
                            authorizedToCreateAndEdit={
                              this.state.authorizedToCreateAndEdit
                            }
                          />
                        )}
                      />
                    )}
                    {this.state.authorizedBypatruljen && (
                      <Route
                        path={sitePaths.grunndata.bypatruljen}
                        render={() => (
                          <BetjentList
                            authorizedToCreateAndEdit={
                              this.state.authorizedToCreateAndEdit
                            }
                          />
                        )}
                      />
                    )}
                    {this.state.authorizedFarger && (
                      <Route
                        path={sitePaths.grunndata.farger}
                        render={() => (
                          <FargerList
                            authorizedToCreateAndEdit={
                              this.state.authorizedToCreateAndEdit
                            }
                          />
                        )}
                      />
                    )}
                    {this.state.authorizedForsinketMakuleringMal && (
                      <Route
                        path={sitePaths.grunndata.forsinketMakulering}
                        render={() => (
                          <ForsinketMakuleringList
                            authorizedToCreateAndEdit={
                              this.state.authorizedToCreateAndEdit
                            }
                          />
                        )}
                      />
                    )}
                    {this.state.authorizedIleggelseInternkoder && (
                      <Route
                        path={sitePaths.grunndata.internkoderIleggelser}
                        render={() => (
                          <InternkoderList
                            authorizedToCreateAndEdit={
                              this.state.authorizedToCreateAndEdit
                            }
                            internkoder={this.props.internkoderIleggelser.filter(
                              (iki) =>
                                iki.aktiv ===
                                  this.props.grunndataFilterForm
                                    .internkoderIleggelserAktivStatus ||
                                this.props.grunndataFilterForm
                                  .internkoderIleggelserAktivStatus === null
                            )}
                            modalToRender="ileggelser"
                          />
                        )}
                      />
                    )}
                    {this.state.authorizedMiljogebyrInternkoder && (
                      <Route
                        path={sitePaths.grunndata.internkoderMiljogebyr}
                        render={() => (
                          <InternkoderList
                            authorizedToCreateAndEdit={
                              this.state.authorizedToCreateAndEdit
                            }
                            modalToRender="miljøgebyr"
                            internkoder={this.props.internkoderMiljogebyr.filter(
                              (ikm) =>
                                ikm.aktiv ===
                                  this.props.grunndataFilterForm
                                    .internkoderMiljogebyrAktivStatus ||
                                this.props.grunndataFilterForm
                                  .internkoderMiljogebyrAktivStatus === null
                            )}
                          />
                        )}
                      />
                    )}
                    {this.state.authorizedKjoretoyMerker && (
                      <Route
                        path={sitePaths.grunndata.kjoretoyMerker}
                        render={() => (
                          <KjoretoyMerkerList
                            authorizedToCreateAndEdit={
                              this.state.authorizedToCreateAndEdit
                            }
                          />
                        )}
                      />
                    )}
                    {this.state.authorizedKjoretoyTyper && (
                      <Route
                        path={sitePaths.grunndata.kjoretoyTyper}
                        render={() => (
                          <KjoretoyTyperList
                            authorizedToCreateAndEdit={
                              this.state.authorizedToCreateAndEdit
                            }
                          />
                        )}
                      />
                    )}
                    {this.state.authorizedNasjoner && (
                      <Route
                        path={sitePaths.grunndata.nasjoner}
                        render={() => (
                          <NasjonerList
                            authorizedToCreateAndEdit={
                              this.state.authorizedToCreateAndEdit
                            }
                          />
                        )}
                      />
                    )}
                    {this.state.authorizedIleggelseOvertredelseskoder && (
                      <Route
                        path={sitePaths.grunndata.overtredelseskoderIleggelser}
                        render={() => (
                          <OvertredelseskoderIleggelseList
                            authorizedToCreateAndEdit={
                              this.state.authorizedToCreateAndEdit
                            }
                          />
                        )}
                      />
                    )}
                    {this.state.authorizedMiljogebyrOvertredelseskoder && (
                      <Route
                        path={sitePaths.grunndata.overtredelseskoderMiljogebyr}
                        render={() => (
                          <OvertredelseskoderMiljogebyrList
                            authorizedToCreateAndEdit={
                              this.state.authorizedToCreateAndEdit
                            }
                          />
                        )}
                      />
                    )}
                    {this.state.authorizedRettekoder && (
                      <Route
                        path={sitePaths.grunndata.rettekoder}
                        render={() => (
                          <RettekoderList
                            authorizedToCreateAndEdit={
                              this.state.authorizedToCreateAndEdit
                            }
                          />
                        )}
                      />
                    )}
                    {this.state.authorizedVedtakskoder && (
                      <Route
                        path={sitePaths.grunndata.vedtakskoder}
                        render={() => (
                          <VedtakskoderList
                            authorizedToCreateAndEdit={
                              this.state.authorizedToCreateAndEdit
                            }
                            modalToRender="vedtakskode"
                            klagekoder={this.props.klagekoder}
                            ileggelsestyper={this.props.ileggelsestyper}
                          />
                        )}
                      />
                    )}
                  </div>
                </React.Fragment>
              )}
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    grunndataFilterForm: state.forms.grunndataFilterForm,
    overtredelseskoderMiljogebyr: state.overtredelseskoderMiljogebyr,
    internkoderIleggelser:
      state.internkoderIleggelser && state.internkoderIleggelser,
    internkoderMiljogebyr:
      state.internkoderMiljogebyr && state.internkoderMiljogebyr,
    klagekoder: state.klagekoder,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators(
    {
      getInternkoderIleggelser,
      getInternkoderMiljogebyr,
      getOvertredelsegrupper,
      getIleggelsestyper,
      getKlageKoder,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(GrunndataPage));
