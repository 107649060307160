import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import "./FlashMessage.css";

class FlashMessage extends Component {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
    this.state = {
      visibile: true,
    };
  }

  componentDidMount() {
    const { type } = this.props.message;
    if (type !== "error") {
      setTimeout(
        () => this.props.deleteFlashMessage(this.props.message.id),
        5000
      );
    }
  }

  onClick() {
    this.props.deleteFlashMessage(this.props.message.id);
  }

  content = (text, allowDangerouslySetInnerHtml) => {
    if (allowDangerouslySetInnerHtml) {
      return <span dangerouslySetInnerHTML={{ __html: text }} />;
    }
    return typeof text !== "string" ? "Server error" : text;
  };

  render() {
    const { type, text, allowDangerouslySetInnerHtml } = this.props.message;
    let content = this.content(text, allowDangerouslySetInnerHtml);
    const className = classnames("alert", {
      "alert-success": type === "success",
      "alert-danger": type === "error",
    });

    return (
      <div>
        <div id="flash-message" className={className}>
          <button onClick={this.onClick} className="close">
            <span>&times;</span>
          </button>
          {content}
        </div>
      </div>
    );
  }
}
FlashMessage.propTypes = {
  message: PropTypes.object.isRequired,
  deleteFlashMessage: PropTypes.func.isRequired,
};

export default FlashMessage;
